<template>
  <div>
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="8">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            新增服务人员
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-form ref="addTechform" :rules="addTechRules" :model="addTech" label-width="120px" style="padding-left:40px">
        <el-row class="left">
          <el-col>
            <el-form-item label="关联用户" prop="wx_id">
              <WxUserSelect :wxInfo="wxInfo" @change="handleWxInfoChange" :update="!addTech.jishi_id"></WxUserSelect>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="姓名" prop="jishi_name">
              <el-input v-model="addTech.jishi_name" maxlength="15" show-word-limit placeholder="请输入姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="性别" prop="jishi_sex">
              <el-radio v-model="addTech.jishi_sex" label="1">男</el-radio>
              <el-radio v-model="addTech.jishi_sex" label="2">女</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="手机号" prop="phone">
              <el-input v-model.number="addTech.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="意向工作城市" prop="yxgzcs">
            	<treeselect v-model="addTech.yxgzcs" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer" :show-count="true" placeholder="请选择工作城市" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="所在地址" prop="address">
              <el-input v-model="addTech.address" placeholder="请输入所在地址"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="经度" prop="lng">
              <el-input v-model="addTech.lng" placeholder="请输入经度"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="8">
            <el-form-item label="纬度" prop="lat">
              <el-input v-model="addTech.lat" placeholder="请输入纬度"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin:5px 0 0 5px">
            <el-button type="primary" plain size="mini" @click="getJingweidu">获取经纬度</el-button>
          </el-col>
          <Map :jingweiVisible.sync="jingweiVisible" @close="closeMapDialog" @subJINGWEI="subJINGWEI"></Map>
        </el-row>
<!--        <el-row class="left">-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="身份证号" prop="sfzh">-->
<!--              <el-input v-model="addTech.sfzh" placeholder="请输入身份证号"></el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
<!--        <el-row class="left">-->
<!--          <el-col :span="24">-->
<!--            <el-form-item label="身份证照片" prop="sfzh_img">-->
<!--              <div class="previewUpload">-->
<!--                <div v-show="addTech.sfzh_img.length>0" class="previewItem" v-for="(item,index) in addTech.sfzh_img" :key="index">-->
<!--                  <img :src="item.img_path" alt="">-->
<!--                  <i class="el-icon-close delIcon" @click="delIdCardItem(index)"></i>-->
<!--                </div>-->
<!--                <div class="previewItems" @click="openUploadDialog((3-addTech.sfzh_img.length),'idCardPhoto')"><i class="el-icon-plus previewadd"></i></div>-->
<!--              </div>-->
<!--              <Upload :uploadVisible.sync="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </el-row>-->
        <el-row class="left">
          <el-col :span="24">
            <el-form-item label="个人生活照" prop="grshz_img">
              <div class="previewUpload">
                <div v-show="addTech.grshz_img.length>0" class="previewItem" v-for="(item,index) in addTech.grshz_img" :key="index">
                  <img :src="item.img_path" alt="">
                  <i class="el-icon-close delIcon" @click="delLifePhotoItem(index)"></i>
                </div>
                <div class="previewItems" @click="openUploadDialog((9-addTech.grshz_img.length),'lifePhoto')"><i class="el-icon-plus previewadd"></i></div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="left">
          <el-col :span="14">
            <el-form-item label="服务类型:" prop="type_id">
              <el-select v-model="addTech.type_id" placeholder="请选择服务类型" multiple clearable style="width: 100%;" @change="typeChange">
                <el-option
                    v-for="item in typeList"
                    :key="item.type_id"
                    :label="item.type_name"
                    :value="item.type_id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-tabs v-model="tabName">
          <el-tab-pane :label="itemTab.type_name" :name="'type@'+itemTab.type_id" v-for="(itemTab,k) in selectTypeList" :key="k">
            <el-row class="left">
              <el-col :span="8">
                <el-form-item label="从业年份" prop="work_year">
                  <el-input v-model.number="itemTab.work_year" placeholder="请输入从业年份"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="left">
              <el-col :span="14">
                <el-form-item label="介绍" prop="profile">
                  <el-input type="textarea" :rows="10" maxlength="300" show-word-limit v-model="itemTab.profile" placeholder="请输入介绍"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="left">
              <el-col :span="24">
                <el-form-item label="资格证书" prop="zgzs_img">
                  <div class="previewUpload">
                    <div v-show="itemTab.zgzs_img.length>0" class="previewItem" v-for="(item,index) in itemTab.zgzs_img" :key="index">
                      <img :src="item.img_path" alt="">
                      <i class="el-icon-close delIcon" @click="delCertificateItem(index,k)"></i>
                    </div>
                    <div class="previewItems" @click="openUploadDialog((6-itemTab.zgzs_img.length),'certificate', k)"><i class="el-icon-plus previewadd"></i></div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row class="left">
              <el-col :span="24">
                <el-form-item label="工作形象照" prop="gzxxz_img">
                  <div class="previewUpload">
                    <div v-show="itemTab.gzxxz_img.length>0" class="previewItem" v-for="(item,index) in itemTab.gzxxz_img" :key="index">
                      <img :src="item.img_path" alt="">
                      <i class="el-icon-close delIcon" @click="delWorkPhotoItem(index,k)"></i>
                    </div>
                    <div class="previewItems" v-if="itemTab.gzxxz_img.length==0" @click="openUploadDialog((1-itemTab.gzxxz_img.length),'workPhoto', k)"><i class="el-icon-plus previewadd"></i></div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
<!--            <el-row class="left">-->
<!--              <el-col :span="24">-->
<!--                <el-form-item label="个人生活照" prop="grshz_img">-->
<!--                  <div class="previewUpload">-->
<!--                    <div v-show="itemTab.grshz_img.length>0" class="previewItem" v-for="(item,index) in itemTab.grshz_img" :key="index">-->
<!--                      <img :src="item.img_path" alt="">-->
<!--                      <i class="el-icon-close delIcon" @click="delLifePhotoItem(index,k)"></i>-->
<!--                    </div>-->
<!--                    <div class="previewItems" @click="openUploadDialog((9-itemTab.grshz_img.length),'lifePhoto', k)"><i class="el-icon-plus previewadd"></i></div>-->
<!--                  </div>-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <el-button type="primary" @click="subAdd">提交</el-button>
      <el-button type="default" @click="back">返回</el-button>
    </el-card>
    <Upload :uploadVisible="uploadVisible" :pictureType="pictureType" :chooseImgCount="chooseImgCount" @close="closeDelDialog" @receiveRes="receiveRes"></Upload>
  </div>
</template>

<script>
import {serviceTypeAll} from "@/api/request/service";

let that;
let timer = null;
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Pagination from '@/components/Pagination/Pagination.vue'
import Map from '@/components/Map/Map.vue'
import Upload from '@/components/Upload/Upload.vue'
import WxUserSelect from "@/components/Form/WxUserSelect.vue";
export default {
  components:{
    WxUserSelect,
    Treeselect,Pagination,Upload,Map
  },
  data () {
    // 验证手机
    var checkphone = (rule, value, cb) => {
      const regPhone =
        /^(0|86|17951)?(13[0-9]|15[0123456789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (regPhone.test(value)) {
        return cb();
      } else {
        cb(new Error("请输入合法手机号"));
      }
    };
    //验证身份证
    var checkIdcard=(rule, value, cb)=>{
      const regId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
        if (regId.test(value)) {
          return cb();
        } else {
          cb(new Error("请输入正确身份证号"));
        }
    }
    return {
      addTechRules:{
        wx_id:[{required: true, message:'请选择关联用户', trigger: 'change'}],
        jishi_name:[{required: true, message:'请输入姓名', trigger: 'blur'}],
        jishi_sex:[{required: true, message:'请输入选择性别', trigger: 'change'}],
        phone:[{required: true, message:'手机号不得为空', trigger: 'blur'}, { validator: checkphone, trigger: "blur" },],
        cynf:[{required: true, message:'请输入从业年份', trigger: 'blur'}, { type: "number", message: "请输入数字" },],
        yxgzcs:[{required: true, message:'请选择意向工作城市', trigger: 'change'},],
        address:[{required: true, message:'请输入所在地址', trigger: 'blur'},],
        lng:[{required: true, message:'请输入经度', trigger: 'blur'},],
        lat:[{required: true, message:'请输入纬度', trigger: 'blur'},],
        type_id: [{required: true, message: '请选择服务类型', trigger: 'change'}],
        jianjie:[{required: true, message:'请输入介绍', trigger: 'blur'},],
        // sfzh:[
        //   {required: true, message:'请输入身份证号', trigger: 'blur'},
        //   { validator: checkIdcard, trigger: "blur" },
        // ],
        // sfzh_img:[
        //   { required: true, message: "请上传图片", trigger: "change" }
        // ],
        // zgzs_img:[
        //   { required: true, message: "请上传图片", trigger: "change" }
        // ],
        // gzxxz_img:[
        //   { required: true, message: "请上传图片", trigger: "change" }
        // ],
        // grshz_img:[
        //   { required: true, message: "请上传图片", trigger: "change" }
        // ]
      },
      addTech:{
      	wx_id: '',
        jishi_name:'',
        jishi_sex:'1',
        phone:'',
        yxgzcs:null,
        address:'',
        lng:'',
        lat:'',
        // sfzh:'',
        // sfzh_img:[],
        grshz_img:[],
        type_id: null
      },
      wxInfo: {},
      uploadVisible:false,
      userVisible:false,
      jingweiVisible:false,
      userForm:{
        userInfo:''
      },
      pictureType:'',
      chooseImgCount:0,
      chooseTypeIndex:0,

      queryInfo: {
        query: "",
        pagenum: 1,
        pagesize: 10,
      },
      total:0,

      city_list: [],
      typeList: [],
      selectTypeList: [],
      tabName: "",
      isSubmit: false
    }
  },
  created() {
    if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			this.getCityList();
      this.getServiceType();
			if(this.$route.query.id){
				this.getJishiDetail();
			}
		}
  },
  methods: {
    async getServiceType() {
      const res = await serviceTypeAll({})
      if (res.code!==200) this.$message.error(res.msg);
      this.typeList = res.data;
    },
  	getCityList(){
  		var url = 'city/city_list';
			let params = {
				is_del: 1
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.city_list = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	getJishiDetail(){
  		var url = 'jishi/jishi_detail';
			let params = {
				jishi_id: this.$route.query.id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					// that.addTech = res.detail;
          that.addTech = {
            jishi_id: res.detail.jishi_id,
            wx_id: res.detail.wx_id,
            jishi_name: res.detail.jishi_name,
            jishi_sex: res.detail.jishi_sex,
            phone: res.detail.phone,
            yxgzcs: res.detail.yxgzcs,
            address: res.detail.address,
            lng: res.detail.lng,
            lat: res.detail.lat,
            grshz_img: res.detail.grshz_img,
            type_id: res.detail.type_id
          }
					that.selectTypeList = res.type_list;
          if (that.selectTypeList.length>0) {
            that.tabName = "type@" + that.selectTypeList[0].type_id;
          }
          that.wxInfo = res.detail.wx_info ? res.detail.wx_info : {};
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
  	//转换菜单数据结构
		normalizer(node) {
			if(node.children && !node.children.length) {
				delete node.children;
			}
			return {
				id: node.city_id,
				label: node.city_name,
				children: node.children,
			};
		},
    back(){
      this.$router.go(-1)
    },
    closeDelDialog(){
      this.uploadVisible=false
    },
    openUploadDialog(count,pictureType,typeIndex=0){
      console.log(count,pictureType,typeIndex)
      this.pictureType = pictureType;
      this.chooseImgCount = count;
      this.chooseTypeIndex = typeIndex;
      this.uploadVisible = true;
    },
    receiveRes(chooseList, pictureType){
      if(pictureType==='idCardPhoto'){
        // this.addTech.sfzh_img=this.addTech.sfzh_img.concat(chooseList)
      }else if(pictureType==='certificate'){
        this.selectTypeList[this.chooseTypeIndex].zgzs_img=this.selectTypeList[this.chooseTypeIndex].zgzs_img.concat(chooseList)
      }else if(pictureType==='workPhoto'){
        this.selectTypeList[this.chooseTypeIndex].gzxxz_img=this.selectTypeList[this.chooseTypeIndex].gzxxz_img.concat(chooseList)
      }else if(pictureType==='lifePhoto'){
        this.addTech.grshz_img=this.addTech.grshz_img.concat(chooseList)
        // this.selectTypeList[this.chooseTypeIndex].grshz_img=this.selectTypeList[this.chooseTypeIndex].grshz_img.concat(chooseList)
      }
      this.uploadVisible=false
    },
    delIdCardItem(index){
      // this.addTech.sfzh_img.splice(index,1)
    },
    delCertificateItem(index,k){
      this.selectTypeList[k].zgzs_img.splice(index,1)
    },
    delWorkPhotoItem(index,k){
      this.selectTypeList[k].gzxxz_img.splice(index,1)
    },
    delLifePhotoItem(index){
      // this.selectTypeList[k].grshz_img.splice(index,1)
      this.addTech.grshz_img.splice(index,1)
    },
    //打开经纬度弹窗
    getJingweidu(){
      this.jingweiVisible=true
    },
    //关闭经纬度弹窗
    closeMapDialog(){
      this.jingweiVisible=false
    },
    //获取经纬度
    subJINGWEI(lat,lng){
      this.addTech.lat=lat
      this.addTech.lng=lng
      this.jingweiVisible=false
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum = 1;
      this.queryInfo.pagesize = newSize;
      this.getCustomList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getCustomList();
    },
    searchUser(){
      this.queryInfo.pagenum = 1;
    	this.getCustomList();
    },
    //提交
    subAdd(){
      this.isSubmit = true;
      this.$refs.addTechform.validate(valid=>{
        if(!valid) return;
        else{
          console.log(this.selectTypeList)
          var msg = "";
          for (let i=0;i<this.selectTypeList.length;i++) {
            const item = this.selectTypeList[i];
            // if(item.zgzs_img.length<1){
            //   msg = "请为“"+item.type_name+"”选择资格证"; break;
            // }
            if(item.gzxxz_img.length<1){
              msg = "请为“"+item.type_name+"”选择工作形象照"; break;
            }
            // if(item.grshz_img.length<1){
            //   msg = "请为“"+item.type_name+"”选择生活照"; break;
            // }
          }
          if (msg) {
            this.$message.error(msg);
            return
          }
          this.selectTypeList.forEach(item=>{
            if(item.zgzs_img.length>0){
              item.zgzs_json = JSON.stringify(item.zgzs_img);
            }
            if(item.gzxxz_img.length>0){
              item.gzxxz_json = JSON.stringify(item.gzxxz_img);
            }
            // if(item.grshz_img.length>0){
            //   item.grshz_json = JSON.stringify(item.grshz_img);
            // }
          })
        	if(timer != null){clearTimeout(timer);}
					timer = setTimeout(() => {
	        	// that.addTech.sfzh_json = JSON.stringify(that.addTech.sfzh_img);
	        	that.addTech.grshz_json = JSON.stringify(that.addTech.grshz_img);
	        	var url = 'jishi/addOrUpdate';
		        let params = that.addTech;
            params.type_json = JSON.stringify(that.selectTypeList);
		        that.fd_post(url, params).then((res) => {
	        		if(res.status){
	        			that.$refs.addTechform.resetFields();
	        			that.$message.success("操作成功");
	        			that.$router.push({path: '/technician_manage'});
	        		}else{
	        			that.$message.error(res.msg);
	        		}
		        }).catch((err) => {
		            that.$message.error('网络错误');
		        });
		      },1000);
        }
      })
    },
    typeChange(e) {
      console.log(e)
      const historyList = this.selectTypeList.filter(item=> e.includes(item.type_id))
      const newList = this.typeList.filter(item=> e.includes(item.type_id))
      this.selectTypeList = newList.map(item=>{
        const historyItem = historyList.find(item2=>item2.type_id===item.type_id)
        if (historyItem) {
          return historyItem
        } else {
          return {
            ...item,
            work_year: "",
            profile: "",
            zgzs_img: [],
            gzxxz_img: [],
            // grshz_img: [],
          }
        }
      })
      if (this.selectTypeList.length > 0) {
        if (this.tabName) {
          const tabId = this.tabName.split("@")
          const typeId = parseInt(tabId[1])
          if (!e.includes(typeId)) {
            this.tabName = "type@" + this.selectTypeList[0].type_id
          }
        } else {
          this.tabName = "type@" + this.selectTypeList[0].type_id
        }
      } else {
        this.tabName = ""
      }
    },
    handleTabClick() {

    },
    handleWxInfoChange(e) {
      this.wxInfo = e;
      this.addTech.wx_id = e.wx_id;
      if (this.isSubmit) {
        this.$refs.addTechform.validate(valid=>{})
      }
    },
  },
  watch: {
    "addTech.yxgzcs": {
      handler(newVal, oldVal) {
        if (newVal) {
          if (this.isSubmit) {
            this.$refs.addTechform.validate(valid=>{})
          }
        }
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import './TechAdd.scss';
</style>